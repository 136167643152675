import useCategories from '@/hooks/useCategories';
import { useMemo } from "react";
import { isTask, Item, ItemType } from "src/types";
import { useGetCategoryResQuery } from '@/redux/features/api';
import { useParams } from "next/navigation";

// how to i allow this to open a form? pass in setOpen
export default function useActive(type: ItemType, path?: string) {
    const swrRes = useGetCategoryResQuery()

    const { category } = (useParams() || {}) as { category?: string[] }

    path = path ?? (category?.join('/') || '')
    const categoryProps = useCategories(path)

    const items: Item[] = useMemo(() => {
        if (!swrRes.data) {
            return []
        }
        if (!path) {
            // TODO: why does this throw an error?
            return swrRes.data.items[type]
        }
        return (swrRes.data.items[type] as Item[]).filter((x: Item) => x.category.startsWith(path as string) && ((isTask(x) && x.hideUntil) ? x.hideUntil < Date.now() : true))
    }, [swrRes.data, path, type])

    // // handles set only
    // const edit = useCallback(async function (id: string, updates: Record<string, any>, promise?: Promise<any>) {
    //     return updateItem({ id, updates: { set: updates } }) // remove automatically handled in updateItem
    // }, [updateItem])

    return {
        ...categoryProps,
        ...swrRes,
        categoryRes: swrRes.data,
        data: swrRes.data?.items[type],
        items, categories: swrRes.data?.categoryUnits,
        parentIdDict: swrRes.data?.parentIdDict,
    }
}
