import ContextMenu, { ContextMenuUnit } from '@/components/common/ContextMenu';
import { useDroppable } from '@dnd-kit/core';
import { endOfDay } from 'date-fns';
import { styled } from 'styled-system/jsx'
import { CalendarItem } from '../../types';
import { categoryColorDynamic } from '@/utils/categories';

/**
 * TODO: make useDroppable same as with daygrid
 * THOUGHT: drop in alldaybox creates task, drop in daygrid creates event?
 * how should useDroppable work, for dragging things external to calendar? (ex. items from search)
 * I think im just gonna not put dndwrapper in time-grid folder for now
 * @returns
 */
const AllDayBox = ({ items, date, pos, calendarId, contextMenu }: {
    items: CalendarItem[], date: Date, pos: number, calendarId: string, contextMenu?: (originalItem: CalendarItem, calendar: CalendarItem) => ContextMenuUnit[],
}) => {

    const { setNodeRef, isOver } = useDroppable({
        id: date.getTime(),
        data: {
            scope: calendarId,
            default: {
                dateStart: date.getTime(),
                dateEnd: endOfDay(date).getTime()
            }
        },
    });

    return (
        <EventBox isOver={isOver} ref={setNodeRef}>
            {
                items.map(e => {
                    return <div key={e.id}><ContextMenu title={e.name} units={contextMenu && contextMenu(e, e)}>
                        <TimegridItem style={categoryColorDynamic(e.colorNum)} >{e.name}</TimegridItem>
                    </ContextMenu>
                    </div>
                })
            }
        </EventBox>
    )
}
// TODO custom style props?

export default AllDayBox

const EventBox = styled('div', {
    variants: {
        isOver: {
            true: {
                background: "$primary4"
            },
            false: {}
        }
    },
    base: {
        overflow: "scroll",
        borderLeft: "$borderThin",
        padding: 5,
        flex: 1,
        gap: 3,
        display: "flex",
        flexDirection: "column"
    }
})

export const TimegridItem = styled('div', {
    base: {
        boxSizing: "border-box",
        flex: 1,
        padding: 5,
        backgroundColor: "$eventbg",
        color: "$eventtxt",
        border: "$borderThin",
        maxHeight: "100%",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        height: "100%",
        borderRadius: "5px",
        textOverflow: "ellipsis"
    }
})